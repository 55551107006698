import { FC, useState, MouseEvent, useMemo, ReactNode } from "react";
import moment from "moment";
import {
  TableSortLabel,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
  Box,
  Collapse,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import SimpleBarReact from "simplebar-react";
import { AppText, Chip, Preloader, Pagination, Tooltip } from "components";
import { SquereButton, CopyButton } from "components/Buttons";
import { CoinCell, StakingControls } from "components/Table";
import { ProgressBar } from "components/Charts";
import {
  RowInfoCell,
  RowGraphImage,
  StyledTableContainer,
  DropDownControls,
  ProgressContainer,
} from "./styled";
import { colors } from "helpers/consts";
import { useMediaQuery } from "helpers/hooks";
import type { StakeType, AvailablePayments } from "helpers/types";
import type { HeadCell, Data, DataValues } from "./types";

import { ReactComponent as ChevronDownIcon } from "assets/icons/chevron-down.svg";
import { ReactComponent as ChevronUpIcon } from "assets/icons/chevron-up.svg";
import { ReactComponent as DotsIcon } from "assets/icons/dots-vertical.svg";
import { ReactComponent as HelpIcon } from "assets/icons/help-circle.svg";

function descendingComparator(a: DataValues, b: DataValues) {
  if (b.value < a.value) {
    return -1;
  }
  if (b.value > a.value) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: DataValues },
  b: { [key in Key]: DataValues }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a[orderBy], b[orderBy])
    : (a, b) => -descendingComparator(a[orderBy], b[orderBy]);
}

function stableSort(
  array: Data[],
  orderBy: string,
  comparator: (a: Data, b: Data) => number
): Data[] {
  if (!orderBy) {
    return array;
  }
  const stabilizedThis = array.map(
    (el, index) => [el, index] as [Data, number]
  );

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: readonly HeadCell[];
}

const EnhancedTableHead: FC<EnhancedTableHeadProps> = ({
  order,
  orderBy,
  onRequestSort,
  headCells,
}) => {
  const { t } = useTranslation();
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(({ id, align, noSort, label, tooltip }) => (
          <TableCell
            key={id}
            align={align ? align : "left"}
            padding="normal"
            sortDirection={orderBy === id ? order : false}
          >
            {!noSort && (
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : "asc"}
                onClick={createSortHandler(id)}
              >
                {label && t(label)}
                {orderBy === id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
                {tooltip && (
                  <Tooltip title={tooltip}>
                    <HelpIcon />
                  </Tooltip>
                )}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface EnhancedTableProps {
  headCells: readonly HeadCell[];
  data?: Data[];
  withPagination?: boolean;
  page?: number;
  setPage?: (num: number) => void;
  perPage?: number;
  totalItems?: number;
  onRowClick?: (rowValue: Data) => void;
  openStakeModal?: (
    initialCoin: AvailablePayments,
    initialStakeStrategyId?: number,
    stakeType?: StakeType
  ) => void;
  customNoData?: ReactNode;
  isLoading?: boolean;
  darkTheme?: boolean;
}

const EnhancedTable: FC<EnhancedTableProps> = ({
  headCells,
  data,
  withPagination,
  perPage,
  openStakeModal,
  onRowClick,
  customNoData,
  page,
  totalItems,
  setPage,
  isLoading,
  darkTheme,
}) => {
  const { t } = useTranslation();
  const desktop = useMediaQuery("(min-width: 48em)");

  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(-1);

  const handleRequestSort = (_: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => {
    !!setPage && setPage(newPage);
  };

  const visibleRows = useMemo(() => {
    if (!data) {
      return null;
    }
    const sortedData = stableSort(data, orderBy, getComparator(order, orderBy));
    if (withPagination) {
      if (rowsPerPage === -1) {
        setRowsPerPage(perPage || 10);
      }
      return sortedData;
    }

    return sortedData;
  }, [order, orderBy, data, withPagination, perPage, rowsPerPage]);

  return (
    <>
      <StyledTableContainer $withBottomBorder={withPagination}>
        <SimpleBarReact
          style={{
            width: "100%",
            height: "100%",
          }}
          tabIndex={-1}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {visibleRows?.map((row, rowIdx) => (
                <CustomTableRow
                  row={row}
                  rowIdx={rowIdx}
                  isDesktop={desktop}
                  openStakeModal={openStakeModal}
                  onRowClick={onRowClick}
                  key={`table-row-${rowIdx}`}
                  darkTheme={darkTheme}
                />
              ))}
            </TableBody>
          </Table>
          {visibleRows && visibleRows.length < 1 && (
            <>
              {customNoData ? (
                customNoData
              ) : (
                <AppText padding="2rem 2.4rem">{t("NO_DATA")}</AppText>
              )}
            </>
          )}
          {isLoading && <Preloader isStatic />}
        </SimpleBarReact>
      </StyledTableContainer>

      {withPagination &&
        typeof totalItems === "number" &&
        typeof page === "number" &&
        data && (
          <Pagination
            totalItems={totalItems}
            page={page}
            itemsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
          />
        )}
    </>
  );
};

interface CustomTableRowProps {
  row: Data;
  rowIdx: number;
  isDesktop: boolean;
  openStakeModal?: (
    initialCoin: AvailablePayments,
    initialStakeStrategyId?: number,
    stakeType?: StakeType
  ) => void;
  onRowClick?: (rowValue: Data) => void;
  darkTheme?: boolean;
}
const CustomTableRow: FC<CustomTableRowProps> = ({
  row,
  rowIdx,
  isDesktop,
  openStakeModal,
  onRowClick,
  darkTheme,
}) => {
  const { t } = useTranslation();
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);
  const [areControlsOpen, setAreControlsOpen] = useState<boolean>(false);
  const [isInfoDropdownOpen, setIsInfoDropdownOpen] = useState<boolean>(false);

  const isOpenable = useMemo(() => {
    const hasTypeDropDown = Object.values(row).find(
      (el) => el.type === "dropdown"
    );
    if (!hasTypeDropDown) {
      return false;
    }
    if (Number(hasTypeDropDown.value) > 0) {
      return true;
    }
    return false;
  }, [row]);
  const toggleInfoOpen = () => setIsInfoOpen(!isInfoOpen);

  const hasControls = useMemo(
    () => Object.values(row).find((el) => el.type === "controls"),
    [row]
  );
  const rowLength = useMemo(() => Object.keys(row).length, [row]);
  const toggleControlsOpen = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAreControlsOpen(!areControlsOpen);
  };

  const toggleInfoDropdownOpen = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsInfoDropdownOpen(!isInfoDropdownOpen);
  };

  return (
    <>
      <TableRow
        tabIndex={-1}
        className={isOpenable || onRowClick !== undefined ? "isOpenable" : ""}
        onClick={
          onRowClick
            ? () => onRowClick(row)
            : isOpenable
            ? () => toggleInfoOpen()
            : () => null
        }
      >
        {Object.values(row).map((el, idx) => {
          if (el.type === "component") {
            return (
              <TableCell
                key={`${el.value}-${rowIdx}-${idx}`}
                align={el.align ? el.align : "right"}
              >
                {el.component}
              </TableCell>
            );
          }
          if (el.type === "date") {
            return (
              <TableCell
                key={`${rowIdx}-${idx}`}
                align={el.align ? el.align : "right"}
              >
                <RowInfoCell>
                  <AppText
                    color={darkTheme ? colors.gray_200 : colors.gray_900}
                  >
                    {moment(el.value).format("M/DD/YY")}
                  </AppText>
                  {el.semiType !== "noTime" && (
                    <AppText
                      isSpan
                      color={darkTheme ? colors.gray_200 : colors.gray_600}
                      fontWeight={400}
                    >
                      {moment(el.value).format("HH:mm:ss")}
                    </AppText>
                  )}
                </RowInfoCell>
              </TableCell>
            );
          }
          if (el.type === "longDate") {
            return (
              <TableCell
                key={`${rowIdx}-${idx}`}
                align={el.align ? el.align : "right"}
              >
                <RowInfoCell>
                  <AppText>
                    {moment(el.value).format("MMM DD, YYYY HH:mm")}
                  </AppText>
                </RowInfoCell>
              </TableCell>
            );
          }
          if (el.type === "image") {
            return (
              <TableCell key={el.type} align={el.align ? el.align : "center"}>
                <RowGraphImage src={el.image} />
              </TableCell>
            );
          }
          if (el.type === "chip") {
            return (
              <TableCell
                key={`${el.type}-${idx}`}
                align={el.align ? el.align : "left"}
              >
                <Chip
                  withBorder={
                    el.subText === "status" || el.subText === "progress"
                  }
                  isNeutral={el.status === "pending"}
                  isError={el.status === "failed"}
                  isSuccess={el.status === "success" || Number(el.value) >= 0}
                  isLarge={el.subText === "progress"}
                  withArrow={
                    el.subText !== "status" &&
                    el.subText !== "progress" &&
                    el.subText !== "value"
                  }
                >
                  {el.subText === "status" || el.subText === "progress"
                    ? t(String(el.value))
                    : `${Number(el.value).toFixed(2)}%`}
                </Chip>
              </TableCell>
            );
          }
          if (el.type === "dropdown") {
            return (
              <TableCell key={el.type}>
                {isOpenable ? (
                  <SquereButton
                    $view="transparent"
                    aria-label="expand row"
                    onClick={() => setIsInfoOpen(!isInfoOpen)}
                  >
                    {isInfoOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  </SquereButton>
                ) : (
                  <div />
                )}
              </TableCell>
            );
          }
          if (el.type === "progress") {
            return (
              <TableCell key={el.type}>
                <ProgressContainer>
                  <ProgressBar
                    value={Number(el.value)}
                    width="8.5rem"
                    noMargin
                  />
                  <AppText>{String(el.value)}%</AppText>
                </ProgressContainer>
              </TableCell>
            );
          }
          if (el.type === "controls") {
            return (
              <TableCell
                key={`${el.value}-${rowIdx}-${idx}`}
                align={el.align ? el.align : "right"}
                className={!isDesktop ? "forCollapse" : ""}
              >
                {isDesktop ? (
                  el.component
                ) : (
                  <SquereButton
                    $view="transparent"
                    onClick={toggleControlsOpen}
                  >
                    <DotsIcon />
                  </SquereButton>
                )}
              </TableCell>
            );
          }
          if (el.type === "decorativeValue") {
            return (
              <TableCell
                key={`${el.value}-${rowIdx}-${idx}`}
                align={el.align ? el.align : "right"}
              >
                <AppText>{t(String(el.visibleValue))}</AppText>
                {el.subText && (
                  <AppText isSpan color={colors.gray_600} fontWeight={400}>
                    {t(el.subText)}
                  </AppText>
                )}
              </TableCell>
            );
          }
          if (el.type === "withCopy") {
            return (
              <TableCell
                key={`${el.value}-${rowIdx}-${idx}`}
                align={el.align ? el.align : "left"}
              >
                <CopyButton copy={String(el.value)} variant="text">
                  {el.visibleValue ? String(el.visibleValue) : String(el.value)}
                </CopyButton>
              </TableCell>
            );
          }

          return (
            <TableCell
              key={`${el.value}-${rowIdx}-${idx}`}
              align={el.align ? el.align : "right"}
            >
              <AppText>{t(String(el.value))}</AppText>
              {el.subText && (
                <AppText isSpan color={colors.gray_600} fontWeight={400}>
                  {t(el.subText)}
                </AppText>
              )}
            </TableCell>
          );
        })}
      </TableRow>
      {!isDesktop && hasControls && (
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={rowLength}>
            <Collapse in={areControlsOpen} timeout="auto">
              <DropDownControls>{hasControls.component}</DropDownControls>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {isOpenable && isInfoOpen && (
        <>
          <TableRow className="insideDropDown">
            <TableCell />

            <TableCell>
              <CoinCell text="Spot" coin="empty" />
            </TableCell>

            <TableCell align="right">
              <AppText>
                {Number(row.amount.value) - Number(row.staking.value)}
              </AppText>
              <AppText isSpan color={colors.gray_600} fontWeight={400}>
                {t(row.amount.subText!)}
              </AppText>
            </TableCell>

            <TableCell align="right">
              <AppText>{String(row.price.value)}</AppText>
              <AppText isSpan color={colors.gray_600} fontWeight={400}>
                {t(row.price.subText!)}
              </AppText>
            </TableCell>

            <TableCell align="right">
              <AppText>
                {(Number(row.amount.value) - Number(row.staking.value)) *
                  Number(row.price.value)}
              </AppText>
              <AppText isSpan color={colors.gray_600} fontWeight={400}>
                USD
              </AppText>
            </TableCell>

            <TableCell />

            <TableCell />

            <TableCell />
          </TableRow>
          <TableRow className="insideDropDown">
            <TableCell />

            <TableCell>
              <CoinCell text="Staking" coin="empty" />
            </TableCell>

            <TableCell align="right">
              <AppText>{Number(row.staking.value)}</AppText>
              <AppText isSpan color={colors.gray_600} fontWeight={400}>
                {t(row.amount.subText!)}
              </AppText>
            </TableCell>

            <TableCell align="right">
              <AppText>{String(row.price.value)}</AppText>
              <AppText isSpan color={colors.gray_600} fontWeight={400}>
                {t(row.price.subText!)}
              </AppText>
            </TableCell>

            <TableCell align="right">
              <AppText>
                {Number(row.staking.value) * Number(row.price.value)}
              </AppText>
              <AppText isSpan color={colors.gray_600} fontWeight={400}>
                USD
              </AppText>
            </TableCell>

            <TableCell />

            <TableCell />

            <TableCell className={!isDesktop ? "forCollapse" : ""}>
              {isDesktop ? (
                <StakingControls
                  initialCoin={row.staking.coin!}
                  openStakeModal={openStakeModal}
                />
              ) : (
                <SquereButton
                  $view="transparent"
                  onClick={toggleInfoDropdownOpen}
                >
                  <DotsIcon />
                </SquereButton>
              )}
            </TableCell>
          </TableRow>
          {!isDesktop && (
            <TableRow>
              <TableCell style={{ padding: 0 }} colSpan={rowLength}>
                <Collapse in={isInfoDropdownOpen} timeout="auto">
                  <DropDownControls>
                    <StakingControls
                      initialCoin={row.staking.coin!}
                      openStakeModal={openStakeModal}
                    />
                  </DropDownControls>
                </Collapse>
              </TableCell>
            </TableRow>
          )}
        </>
      )}
    </>
  );
};

export default EnhancedTable;
